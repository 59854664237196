'use strict'

###*
 # @ngdoc service
 # @name mundoNavigation.factory:MundoNavigation

 # @description

###
angular
  .module 'mundoNavigation'
  .factory 'MundoNavigation', [
    '$rootScope'
    '$log'
    '$state'
    ($rootScope, $log, $state) ->
      MundoNavigationBase = {}
      MundoNavigationBase.sections = {}
      MundoNavigationBase.activeSection = null

      MundoNavigationBase.toggleActiveSection = (section, event) ->
        if event?
          event.preventDefault(true)

        if MundoNavigationBase.activeSection? and (section.id == MundoNavigationBase.activeSection.id)
          MundoNavigationBase.toggleBodyStyle false
          MundoNavigationBase.activeSection = null
          section.active = false
        else
          if(section.items.length == 1)
            MundoNavigationBase.toggleBodyStyle false
            if(MundoNavigationBase.activeSection?)
              MundoNavigationBase.activeSection.active = false
            MundoNavigationBase.activeSection = null
            $state.go section.items[0].state
            # Go straight to the first controller
          else
            MundoNavigationBase.toggleBodyStyle true
            MundoNavigationBase.activeSection = section
            section.active = true

          for v in MundoNavigationBase.sections when section.id != v.id
            v.active = false

      MundoNavigationBase.getActiveSection = () ->
        return MundoNavigationBase.activeSection

      MundoNavigationBase.setActiveSection = (section = null) ->
        activeSection = MundoNavigationBase.getActiveSection()

        if not section?
          if activeSection?
            MundoNavigationBase.toggleActiveSection activeSection
        else
          MundoNavigationBase.toggleActiveSection section

      MundoNavigationBase.addSection = (section) ->
        if not section.id?
          section.id = section.state

        MundoNavigationBase.sections[section.id] = section

      MundoNavigationBase.toggleBodyStyle = (state) ->
        angular.element(document).find('body').toggleClass 'mundo-navigation-active', state
        $rootScope.$emit 'navigation::toggle'

      MundoNavigationBase
  ]
